import gql from 'graphql-tag';

export const OFFER_CREATE = gql`
  mutation OfferCreate ($input: OfferCreateInput!) {
    offerCreate(input: $input)
    {
      offer {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const OFFER_EDIT = gql`
  query OfferEdit($oid: Integer!) {
    offer (oid: $oid) {
      oid
      id
      active
      code
      name
      startAt
      endAt
      offerType
      discountType
      discountValue
      description
      discountLogic
      dependencyLogic
      quantity
      minThreshold
      confirmationText
      countries {
        id
        name
      }
      products {
        oid
        id
        title: name
      }
      dependencyProducts {
        oid
        id
        title: name
      }
      _dependencyProductStats {
        count
      }
      discountProducts {
        oid
        id
        title: name
      }
      _discountProductStats {
        count
      }
      _productStats {
        count
      }
    }
  }
`;

export const OFFER_SET_COUNTRIES = gql`
  mutation OfferSetCountries ($input: OfferSetCountriesInput!) {
    offerSetCountries(input: $input)
    {
      offer {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const OFFER_UPDATE = gql`
  mutation OfferUpdate ($input: OfferUpdateInput!) {
    offerUpdate(input: $input)
    {
      offers {
        id
        oid
        countries {
          id
          oid
        }
      }
      success
      errors
    }
  }
`;

export const OFFER_DELETE = gql`
  mutation OfferDelete ($input: OfferDeleteInput!) {
    offerDelete(input: $input)
    {
      success
      errors
      offers { id }
    }
  }
`;
export const OFFER_PRODUCT = gql`
  query OfferProducts($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    offer(oid: $oid) {
      products (page: $page, perPage: $perPage) {
        oid
        id
        title: name
      }
      _productStats {
        count
      }
    }
  }
`;


export const OFFER_DEPENDENCY_PRODUCT = gql`
  query OfferDependencyProduct($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    offer(oid: $oid) {
      dependencyProducts (page: $page, perPage: $perPage) {
        oid
        id
        title: name
      }
      _dependencyProductStats {
        count
      }
    }
  }
`;


export const OFFER_DISCOUNT_PRODUCT = gql`
  query OfferDiscountProduct($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    offer(oid: $oid) {
      discountProducts (page: $page, perPage: $perPage) {
        oid
        id
        title: name
      }
      _discountProductStats {
        count
      }
    }
  }
`;


export const OFFER_CREATE_PRODUCT = gql`
  mutation OfferCreateProduct ($input: OfferedProductCreateInput!) {
    offeredProductCreate(input: $input)
    {
      offeredProduct {
        id
        oid
        offer {
          id
          oid
          _dependencyProductStats {
            count
          }
          _discountProductStats {
            count
          }
          discountProducts {
            oid
            id
            title: name
          }
          dependencyProducts {
            oid
            id
            title: name
          }
        }
      }
      success
      errors
    }
  }
`;

export const OFFER_DELETE_PRODUCT = gql`
  mutation OfferDeleteProduct ($input: OfferedProductDeleteInput!) {
    offeredProductDelete(input: $input)
    {
      success 
      errors
    }
  }
`;

export const OFFER_UPDATE_PRODUCT = gql`
  mutation OfferUpdateProduct ($input: OfferedProductUpdateInput!) {
    offeredProductUpdate(input: $input)
    {
      offeredProducts{
        id
        oid
        offer {
          id
          oid
          discountProducts {
            id
            oid
            title: name
          }
          dependencyProducts {
            id
            oid
            title: name
          }
        }
      }
      success
      errors
    }
  }
`;

export const OFFER_PRODUCTS = gql`
  query OfferedProducts {
    offeredProducts {
      id
      oid
      offer {
        id
        oid
        discountProducts {
          id
          oid
          title: name
        }
        dependencyProducts {
          id
          oid
          title: name
        }
      }
    }
  }
`;

export const OFFERS = gql`
  query Offers($sortString: [Str], $page: Integer!, $perPage: Integer!) {
    _offerStats {
      count
    }
    offers(sortString: $sortString, page: $page, perPage: $perPage) {
      id
      name
    }
  }
`;

export const CHECK_OFFER_CODE = gql`
  query CheckOfferCode($filterString: [Str]) {
    _offerStats(filterString: $filterString) {
      count
    }
  }
`;