import gql from 'graphql-tag';
import pluralize from 'pluralize';
import { commonRelatedBody } from './relatedPanel';

export const TAXONOMY_IMAGE = gql`
  query TaxonomyImage ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _imageStats {
        count
      }
      images (perPage: $perPage, page: $page) {
        id
        oid
        title
        fileSize
        height
        width
      }
    }
  }
`;
export const TAXONOMY_AUDIO = gql`
  query TaxonomyAudio ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _audioStats {
        count
      }
      audios (perPage: $perPage, page: $page) {
        id
        oid
        title
        fileSize
      }
    }
  }
`;

export const TAXONOMY_FILES = gql`
  query TaxonomyFiles ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _fileStats {
        count
      }
      files (perPage: $perPage, page: $page) {
        id
        oid
        title
        fileSize
      }
    }
  }
`;

export const IMAGE_EDIT = gql`
  query ImageEdit ($oid: Integer!) {
    image(oid: $oid) {
      id
      oid
      title
      caption
      description
      credit
      searchable
      childImage {
        id
        oid
      }
      url
      absoluteUrl
      altText
      remote
      externalId
      width
      height
      fileSize
      taxonomies {
        id
      }
    }
  }
`;

export const VIDEO_EDIT = gql`
  query VideoEdit ($oid: Integer!) {
    video(oid: $oid) {
      id
      oid
      title
      description
      searchable
      url
      absoluteUrl
      remote
      duration
      durationUnit
      externalId
      releaseDate
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      meta {
        id
        keywords
        description
      }
    }
  }
`;

export const AUDIO_EDIT = gql`
  query AudioEdit ($oid: Integer!) {
    audio(oid: $oid) {
      id
      oid
      title
      description
      searchable
      url
      absoluteUrl
      remote
      duration
      durationUnit
      externalId
      releaseDate
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
    }
  }
`;

export const FILE_EDIT = gql`
  query FileEdit ($oid: Integer!) {
    file(oid: $oid) {
      id
      oid
      title
      description
      searchable
      url
      absoluteUrl
      remote
      externalId
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
    }
  }
`;

export const fragementMediaTaxonomiesEdit = () => {
  return `
    id
    description
    topics {
      id
      title
      parent {
        id
        title
      }
    }
    sitePlacements {
      id
      title
      parent {
        id
        title
      }
    }
    classifications {
      id
      title
      parent {
        id
        title
      }
    }
    categories {
      id
      title
      parent {
        id
        title
      }
    }
    taxonomies {
      id
      title
      parent {
        id
        title
      }
    }
    taxonomiesDescendedFrom(taxonomyId: "TOPIC_CATEGORY") {
      id
      title
      parent {
        id
        title
      }
    }
  `;
}

export const IMAGE_TAXONOMIES_EDIT = gql`
  query ImageTaxonomiesEdit($oid: Integer!) {
    image(oid: $oid) {
      ${fragementMediaTaxonomiesEdit()}
      contributors {
        oid
        id
        fullName
      }
    }
  }
`;

export const AUDIO_TAXONOMIES_EDIT = gql`
  query AudioTaxonomiesEdit($oid: Integer!) {
    audio(oid: $oid) {
      ${fragementMediaTaxonomiesEdit()}
    }
  }
`;

export const VIDEO_TAXONOMIES_EDIT = gql`
  query VideoTaxonomiesEdit($oid: Integer!) {
    video(oid: $oid) {
      ${fragementMediaTaxonomiesEdit()}
    }
  }
`;

export const FILE_TAXONOMIES_EDIT = gql`
  query FileTaxonomiesEdit($oid: Integer!) {
    file(oid: $oid) {
      ${fragementMediaTaxonomiesEdit()}
    }
  }
`;

export const IMAGE_UPDATE = gql`
  mutation ImageUpdate($input: ImageUpdateInput!) {
    imageUpdate(input: $input) {
      images {
        id
        oid
        url
        absoluteUrl
        title
        height
        width
        fileSize
        caption
        credit
      }
      success
      errors
    }
  }
`;

export const VIDEO_UPDATE = gql`
  mutation VideoUpdate($input: VideoUpdateInput!) {
    videoUpdate(input: $input) {
      videos {
        id
        oid
        url
        absoluteUrl
      }
      success
      errors
    }
  }
`;

export const AUDIO_UPDATE = gql`
  mutation AudioUpdate($input: AudioUpdateInput!) {
    audioUpdate(input: $input) {
      audios {
        id
        oid
        url
        absoluteUrl
      }
      success
      errors
    }
  }
`;

export const FILE_UPDATE = gql`
  mutation FileUpdate($input: FileUpdateInput!) {
    fileUpdate(input: $input) {
      files {
        id
        oid
        url
        absoluteUrl
      }
      success
      errors
    }
  }
`;

export const IMAGE_ADD_TAXONOMIES = gql`
  mutation imageAddTaxonomies ($input: ImageAddTaxonomiesInput!) {
    imageAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const IMAGE_SET_AUTHORS = gql`
  mutation ImageSetContributors ($input: ImageSetContributorsInput!) {
    imageSetContributors(input: $input){
      success
      errors
    }
  }
`;

export const IMAGE_REMOVE_TAXONOMIES = gql`
  mutation ImageRemoveTaxonomies ($input: ImageRemoveTaxonomiesInput!) {
    imageRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const AUDIO_ADD_TAXONOMIES = gql`
  mutation audioAddTaxonomies ($input: AudioAddTaxonomiesInput!) {
    audioAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const AUDIO_REMOVE_TAXONOMIES = gql`
  mutation AudioRemoveTaxonomies ($input: AudioRemoveTaxonomiesInput!) {
    audioRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const VIDEO_ADD_TAXONOMIES = gql`
  mutation videoAddTaxonomies ($input: VideoAddTaxonomiesInput!) {
    videoAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const VIDEO_REMOVE_TAXONOMIES = gql`
  mutation VideoRemoveTaxonomies ($input: VideoRemoveTaxonomiesInput!) {
    videoRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const FILE_ADD_TAXONOMIES = gql`
  mutation fileAddTaxonomies ($input: FileAddTaxonomiesInput!) {
    fileAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const FILE_REMOVE_TAXONOMIES = gql`
  mutation FileRemoveTaxonomies ($input: FileRemoveTaxonomiesInput!) {
    fileRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const IMAGE_ASSIGNED_TO = gql`
  query ImageAssignedTo($oid: Integer!) {
    image (oid:$oid) {
      id
      articles {
        id
        oid
        title
        postAt
      }
      articlesByMedia {
        id
        oid
        title
        postAt
      }
      authors {
        id
        oid
        firstName
        lastName
      }
      blogs {
        id
        oid
        title
      }
      classifieds {
        id
        oid
        title
        postAt
      }
      companies {
        id
        oid
        title: name
        postAt
      }
      companiesByMedia {
        id
        oid
        title: name
        postAt
      }
      editorialContents {
        id
        oid
        title: name
      }
      events {
        id
        oid
        title
      }
      eventsByMedia {
        id
        oid
        title
      }
      galleries {
        id
        oid
        title
      }
      menus {
        id
        title
      }
      products {
        id
        oid
        title: name
      }
      productsByMedia {
        id
        oid
        title: name
      }
      editions {
        id
        oid
        title
        postAt
        publication {
          oid
        }
      }
      taxonomies {
        id
        title
      }
      taxonomiesByMain {
        id
        title
      }
      newsletterIssues {
        id
        oid
        title
        postAt
        newsletter {
          oid
        }
      }
    }
  }
`;

export const VIDEO_ASSIGNED_TO = gql`
  query VideoAssignedTo($oid: Integer!) {
    video (oid:$oid) {
      id
      articles {
        id
        oid
        title
        postAt
      }
      companies {
        id
        oid
        title: name
        postAt
      }
      events {
        id
        oid
        title
      }
      products {
        id
        oid
        title: name
      }
      taxonomies {
        id
        title
      }
    }
  }
`;

export const AUDIO_ASSIGNED_TO = gql`
  query AudioAssignedTo($oid: Integer!) {
    audio (oid:$oid) {
      id
      articles {
        id
        oid
        title
        postAt
      }
      companies {
        id
        oid
        title: name
        postAt
      }
      events {
        id
        oid
        title
      }
      products {
        id
        oid
        title: name
      }
      taxonomies {
        id
        title
      }
    }
  }
`;

export const FILE_ASSIGNED_TO = gql`
  query FileAssignedTo($oid: Integer!) {
    file (oid:$oid) {
      id
      articles {
        id
        oid
        title
        postAt
      }
      newsletters {
        id
        title
      }
      newsletterIssues {
        id
        oid
        title
        postAt
        newsletter {
          id
          oid
          title
        }
      }
      products {
        id
        oid
        title: name
      }
    }
  }
`;

export const MEDIA_RELATED_MUTATIONS = (mutationType: string, ids: string[], rootNameId: string, addRemove: string, rootName: string, sortingOrders: number[] | null = null) => {
  //First char convert in uppercase
  const mutationTypeName = mutationType === "audio" ? "audios" : pluralize.plural(mutationType);
  const mutationTypeNameUpperCase = mutationTypeName.charAt(0).toUpperCase() + mutationTypeName.slice(1);
  const IdsList = mutationType == 'item' ? 'unionIds' : `${mutationType}Ids`;
  const mutationName = `${rootName}${addRemove}${mutationTypeNameUpperCase}`;
  let input = `{ ${IdsList}: ["${ids.join('","')}"], ${rootName}Id: "${rootNameId}" }`;


  if (sortingOrders?.length) {
    input = `{ ${IdsList}: ["${ids.join('","')}"], ${rootName}Id: "${rootNameId}", sortingOrders: ["${sortingOrders.join('","')}"] }`;
  }

  let query = `${mutationName}(input: ${input}) {
    ${commonRelatedBody(mutationTypeName)}
  }`;

  if (mutationType == 'item' && rootName == 'product') {
    query = `${mutationName}(input: ${input}) {
      product {
        id
        name
        sellableFiles {
          oid
          id
          url
        }
      }
      success
      errors
    }`;
  }

  return { query };
};

export const FOLDER_STATS = gql`
  query folderStats($path: URL) {
    folder(path: $path) {
      folderCount
    }
  }
`;

export const FOLDER = gql`
  query Folder($path: URL) {
    folder(path: $path) {
      fileCount
      folderCount
      empty
      path
      folders {
        name
        fileCount
        folderCount
        empty
        path
      }
    }
  }
`;

export const FOLDER_TREE = gql`
  query FolderTree($path: URL) {
    folder(path: $path) {
      id:path
      title:name
      folderCount
      folders {
        folderCount
        title:name
        id:path
      }
    }
  }
`;

export const ROOT_FOLDER = gql`
  query RootFolder($path: URL) {
    folder(path: $path) {
      fileCount
      folderCount
      empty
      path
    }
  }
`;

export const IMAGE_CREATE = gql`
  mutation ImageCreate ($input: ImageCreateInput!) {
    imageCreate(input: $input)
    {
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
        caption
        credit
      }
      success
      errors
    }
  }
`;

export const FILE_CREATE = gql`
  mutation FileCreate ($input: FileCreateInput!) {
    fileCreate(input: $input)
    {
      file {
        id
        oid
        url
        absoluteUrl
      }
      success
      errors
    }
  }
`;

export const AUDIO_CREATE = gql`
  mutation AudioCreate ($input: AudioCreateInput!) {
    audioCreate(input: $input)
    {
      audio {
        id
        oid
        url
        absoluteUrl
      }
    }
  }
`;

export const VIDEO_CREATE = gql`
  mutation VideoCreate ($input: VideoCreateInput!) {
    videoCreate(input: $input)
    {
      video {
        id
        oid
        url
        absoluteUrl
      }
    }
  }
`;

export const FOLDER_CREATE = gql`
  mutation FolderCreate($input: FolderCreateInput!) {
    folderCreate(input: $input) {
      folder {
        name
        folderCount
        fileCount
        empty
        path
        parent {
          path
          name
        }
      }
      success
      errors
    }
  }
`;

export const FOLDER_DELETE = gql`
  mutation FolderDelete($input: FolderDeleteInput!) {
    folderDelete(input: $input) {
      success
      errors
    }
  }
`;

export const IMAGE_DELETE = gql`
  mutation ImageDelete ($input: ImageDeleteInput!) {
    imageDelete(input: $input)
    {
      images { id }
      success
      errors
    }
  }
`;

export const AUDIO_DELETE = gql`
  mutation AudioDelete ($input: AudioDeleteInput!) {
    audioDelete(input: $input)
    {
      audios { id }
      success
      errors
    }
  }
`;

export const VIDEO_DELETE = gql`
  mutation VideoDelete ($input: VideoDeleteInput!) {
    videoDelete(input: $input)
    {
      videos { id }
      success
      errors
    }
  }
`;

export const FILE_DELETE = gql`
  mutation FileDelete ($input: FileDeleteInput!) {
    fileDelete(input: $input)
    {
      files { id }
      success
      errors
    }
  }
`;

export const FILE_PRODUCTS_EDIT = gql`
  query FileProductsEdit($oid: Integer!) {
    file (oid: $oid) {
      id
      products {
        id
        oid
        internalId
        externalId
        availability
        classifications {
          id
          title
          parent {
            id
            title
          }
        }
        description
        name
        shippable
        taxable
        teaser
        title
        maxQuantity
        costs {
          id
          cost
          originalCost
          minQuantity
          maxQuantity
        }
      }
    }
  }
`;

export const VIDEO_PRODUCTS_EDIT = gql`
  query VideoProductsEdit($oid: Integer!) {
    video (oid: $oid) {
      id
      products {
        id
        oid
        internalId
        externalId
        availability
        classifications {
          id
          title
          parent {
            id
            title
          }
        }
        description
        name
        shippable
        taxable
        teaser
        title
        maxQuantity
        costs {
          id
          cost
          originalCost
          minQuantity
          maxQuantity
        }
      }
    }
  }
`;

export const AUDIO_PRODUCTS_EDIT = gql`
  query AudioProductsEdit($oid: Integer!) {
    audio (oid: $oid) {
      id
      products {
        id
        oid
        internalId
        externalId
        availability
        classifications {
          id
          title
          parent {
            id
            title
          }
        }
        description
        name
        shippable
        taxable
        teaser
        title
        maxQuantity
        costs {
          id
          cost
          originalCost
          minQuantity
          maxQuantity
        }
      }
    }
  }
`;

export const FILE_SET_PRODUCTS = gql`
  mutation FileSetProducts ($input: FileSetProductsInput!) {
    fileSetProducts(input: $input){
      success
      errors
    }
  }
`;

export const AUDIO_SET_PRODUCTS = gql`
  mutation AudioSetProducts ($input: AudioSetProductsInput!) {
    audioSetProducts(input: $input){
      success
      errors
    }
  }
`;

export const VIDEO_SET_PRODUCTS = gql`
  mutation VideoSetProducts ($input: VideoSetProductsInput!) {
    videoSetProducts(input: $input){
      success
      errors
    }
  }
`;

export const TAXONOMY_VIDEOS = gql`
  query TaxonomyVideos ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _videoStats {
        count
      }
      videos (perPage: $perPage, page: $page) {
        id
        oid
        title
        fileSize
      }
    }
  }
`;

export const FOLDER_FILES = gql`
 query FolderFiles($path: URL, $types: [FileEnum], $page: Integer, $perPage: Integer, $sortString: Str) {
  folder(path: $path) {
    name
    path
    fileCount(types: $types)
    files(types: $types, page: $page, perPage: $perPage, sortString: $sortString) {
      item {
        ... on Video {
          id
          oid
          title
          url
          absoluteUrl
          image {
            id
            url
            absoluteUrl
            oid
            height
            width
            fileSize
            altText
          }
          remote
          updatedAt
          fileSize
        }
        ... on Image {
          id
          oid
          title
          url
          absoluteUrl
          caption
          height
          width
          remote
          updatedAt
          fileSize
          altText
        }
        ... on File {
          id
          oid
          title
          url
          absoluteUrl
          remote
          updatedAt
          fileSize
        }
        ... on Audio {
          id
          oid
          title
          url
          absoluteUrl
          image {
            id
            url
            absoluteUrl
            oid
            height
            width
            fileSize
            altText
          }
          remote
          updatedAt
          fileSize
        }
        __typename
      }
    }
  }
}
 `;

export const imageMetaData = () => {
  return `
    id
    oid
    title
    url
    createdAt
    updatedAt
    height
    width
    fileSize
    altText
    credit
    caption
    searchable
    description
    topics {
      oid
      title
    }
    sitePlacements {
      oid
      title
    }
    classifications {
      oid
      title
    }
    taxonomiesDescendedFrom(taxonomyId: "TOPIC_CATEGORY") {
      oid
      title
    }
    articles {
      id
      title
    }
    products {
      id
      name
    }
    events {
      id
      title
    }
    companies {
      id
      name
    }
    taxonomies {
      id
      title
    }
    taxonomiesByMain {
      id
      title
    }
    galleries {
      id
      title
    }
    blogs {
      id
      title
    }
    editions {
      id
      title
    }
    authors {
      id
      fullName
    }
    classifieds {
      id
      title
    }
    newsletterIssues {
      id
      title
    }
    remote
  `;
}

export const SELECTED_IMAGE_MUTATION = (ids: Array<string>, variables: {}, param: string, counter: number, query: string) => {
  for (let index = 0; index < ids.length; index++) {
    let key = `image${counter}`;
    let value = ids[counter];
    variables[key] = value;
    if (param != '') param += ',';
    param += "$" + key + ":IDType";
    query += `image${counter}: image(id: $${key} ) {
        ${imageMetaData()}
      }`
    counter++;
  }
  return { query, variables, param, counter }
};