import styled, { css } from 'styled-components';
import Colors from 'styles/colors';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(1px);
  filter: blur(2px);
  z-index: 1;
`

export const Container = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  height: 100%;
  z-index: 2;
  box-shadow: 0 0 50px rgba(39,59,80,0.45);
  will-change: transform;
  transform: translateX(110%);

  ${({showAsModal}) => showAsModal && css`
    bottom: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.6);
    overflow: scroll;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `};

  ${(props) => props.size === 'default' && css`
    width: 100%;
    max-width: 350px;
  `}

  ${(props) => props.size === 'wide' && css`
    width: 100%;
    max-width: 600px;
  `}

  ${(props) => props.size === 'medium' && css`
    width: 100%;
    max-width: 500px;
  `}

  ${(props) => props.size === 'large' && css`
    width: 100%;
    max-width: 700px;
  `}

  ${({ show, showAsModal}) => show && showAsModal && css`
    opacity: 1;
    max-width: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const Header = styled.header`
  background-color: ${Colors.nileBlue};
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.cadetBlue};
  font-size: 18px;
  text-transform: uppercase;
  padding: 0 20px;

  .button-close {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .fa-arrow-circle-right {
    font-size: 28px;

    path {
      fill: ${Colors.cadetBlue};
    }
  }

  .drawer-title {
    width: auto;
    padding-right: 20px;
  }
`;

export const Body = styled.div`
  padding: ${ (props) => props.hasTabs === true ? 0 : "20px" };
  overflow: auto;
  height: ${({ showAsModal}) => showAsModal ? 'auto' : `calc(100vh - 30px)`};
  overflow-x: hidden;
  box-shadow: ${({showAsModal}) => showAsModal ? '0px 0px 14px 2px rgba(0,0,0,0.45)' : 'none'}
`;

export const subTitle = styled.div`
  border-left: 1px solid ${Colors.lightSlateGrey};
  padding-left: 20px;
  display: flex;
  align-items: center;
  flex: auto;
  font-size: 14px;
  height: 100%;
`;

export const PopConfirmWrapper = styled.div`
  display: inline-block;
`;
