import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SETTINGS } from 'graphql/entry';
import { Settings, SettingsVariables } from 'graphql/types/Settings';

const useSettings = (names, fetchPolicy) => {
  // Memoize the filter string to avoid unnecessary recalculations
  const systemSettingsStr = useMemo(
    () => names.map((setting) => `name = ${setting}`).join(' %OR% '),
    [names]
  );

  // Default variables for the query
  const defaultVariables = useMemo(
    () => ({
      filterString: [systemSettingsStr],
    }),
    [systemSettingsStr]
  );

  // Apollo useQuery hook
  const { loading, data, error } = useQuery<Settings, SettingsVariables>(SETTINGS, {
    variables: defaultVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
  });

  // Memoize settings derived from data to avoid recomputations
  const settings = useMemo(() => {
    if (!data?.settings) return {};

    return data.settings.reduce((acc, item) => {
      acc[item.name] = item;
      return acc;
    }, {});
  }, [data]);

  return {
    loading,
    error,
    settings,
  };
};

export default useSettings;