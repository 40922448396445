const Colors = {
  iceburg:         '#DCF1F0',
  onahau:          '#C3E1F2',
  beaublue:        '#BAD6E0',
  havelockBlue:    '#5187BD',
  pictonBlue:      '#48A8E0',
  curiousBlue:     '#308DC4',
  pickledBlueWood: '#32475D',
  textBlue:       '#4D76A0',
  nileBlue:        '#273B50',
  bigStone:        '#182737',
  oxfordBlue:      '#072341',
  drover:          '#FFEEAB',
  yellow:          '#FFE786',
  arylideYellow:   '#EDD369',
  sahara:          '#B39726',
  jaggedIce:       '#c4e8e7',
  blueRomance:     '#D8F6DC',
  fringyFlower:    '#BAE0BF',
  chateauGreen:    '#3AB361',
  darkGreen:       '#2b8884',
  verdigris:       '#3AB3AF',
  hippieGreen:     '#5D9064',
  whiteSmoke:      '#F6F6F6',
  white:           '#ECEDEF',
  mercury:         '#E2E5E8',
  zumthor:         '#CDD3D9',
  iron:            '#D3D7DC',
  cadetBlue:       '#B2BCC6',
  heather:         '#B6C1CC',
  nevada:          '#636E79',
  fiord:           '#4F5A65',
  lightSlateGrey:  '#778899',
  regentGray:      '#8C959D',
  greyChateau:     '#939ea9',
  hintOfRed:       '#f9f9f9',
  roseWhite:       '#FEE8E9',
  cherub:          '#F6D8D8',
  cavernPink:      '#E0BABA',
  sunGlow:         '#DB6B6B',
  roseTaupe:       '#905D5D',
  purple:          '#9D4DFF',
  lightGreen:      '#A6C430',
  lightGrey:       '#EBEBEB',
  pureWhite:       '#FFFFFF',
  black:           '#000000',
  darkBlue:        '#1890FF',
  fadedGlaciers:   '#F0FCFF',
  mediumDarkGrey:       '#595959',
  borderGrey:      '#E8E8E8',
  grayishBlue:     '#5BC0DE',
};

export default Colors;
